var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',{attrs:{"loading":_vm.loadingTree}},[_c('v-card-title',[_c('h3',{staticClass:"ml-3",staticStyle:{"color":"#e33354"}},[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ProductCategoryModalForm',{attrs:{"modalData":_vm.modalData,"permissions":_vm.permissions,"statuses":_vm.statuses,"endPoint":_vm.endPoint,"productCategoryCollection":_vm.productCategoryCollection,"propertyCollection":_vm.propertyCollection},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}}),_c('v-treeview',{attrs:{"open-all":"","items":_vm.computedProductCategoryCollection,"item-key":"id","dense":"","item-children":"children","hoverable":""},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.translated)?_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("check_circle")]):_vm._e()]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleClickItem(item)}}},[_vm._v(" "+_vm._s(_vm.$helpers.getTranslated(item.translations).name)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(_vm.permissionCan('delete') && item.deletable)?_c('v-icon',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }